import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ModalService } from 'src/app/@domain/modal/modal.service';
import { Modal } from '../../../@domain/modal/modal.enum';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: [ './modal.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ AsyncPipe ],
})
export class ModalComponent {
    private modalService = inject(ModalService);

    public isSmall = input(false);
    public tag = input.required<Modal>();
    public isOpen = computed(() => this.modalService.openedModal() === this.tag());
}
