export enum Modal {
    CreateAuthor = 'CreateAuthor',
    LoadVideo = 'LoadVideo',
    EditPlaylist = 'EditPlaylist',
    CreatePlaylist = 'CreatePlaylist',
    WelcomeNotice = 'WelcomeNotice',
    PasswordChanged = 'PasswordChanged',
    CheckEmail = 'CheckEmail',
    PasswordReset = 'PasswordReset',
}
